const environmentMap = {
  test: 'https://server.chatbot.test.juridischloket-dev.nl',
  acc: 'https://server.chatbot.acceptatie.juridischloket-dev.nl',
  acceptation: 'https://server.chatbot.acceptatie.juridischloket-dev.nl',
  prod: 'https://botservice.juridischloket.nl',
  production: 'https://botservice.juridischloket.nl',
  local: 'https://server.chatbot.test.juridischloket-dev.nl',
}

const getEnvironment = (): EnvironmentKey => {
  const environment = (window as any).EnvironmentSettings?.environment

  if (environment) {
    return environment
  }

  const origin = window.location.origin

  if (origin.includes('-t-') || origin.includes('test')) {
    return 'test'
  }

  if (origin.includes('-a-') || origin.includes('acceptatie')) {
    return 'acceptation'
  }

  if (origin.includes('-p-') || origin.includes('production')) {
    return 'production'
  }

  if (origin.includes('localhost')) {
    return 'test'
  }

  return 'test'
}

type EnvironmentKey = keyof typeof environmentMap

const getApiUrl = (env: EnvironmentKey) => {
  return environmentMap[env]
}

export const config = {
  api: getApiUrl(getEnvironment()),
}
